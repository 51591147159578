
.pdp-sticky-cta-pricing {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 4px;

    .product-price {
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.25012rem;
        font-size: .875rem;
        font-weight: 700;

        @media (min-width: 64rem) {
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 600;
            line-height: 1.875rem;
            letter-spacing: .03rem;
        }
    }

    .pricing-installment{
        font-size: .875rem;
        font-weight: 500;
        line-height: 1.25012rem;

        @media (min-width: 64rem) {
                font-size: 1.125rem;
                font-weight: 400;
        }
    }

    .btn-link {
        padding 0 !important
        color: $color-bnn;
        cursor pointer

        &:hover:before {
            background: transparent !important
        }

        .v-icon {
            color: $color-bnn
        }
    }
}
